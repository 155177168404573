































.no-remap {
  width: 100%;
  height: auto;
  color: #555555;
  background: #eee;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px;
  border-radius: 4px;
}
